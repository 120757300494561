import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../../../context/userContext'
import { getDoc, setDoc, doc } from "firebase/firestore";
import "./Notes.css";
import { IoIosCloseCircle } from "react-icons/io";
import DraggableWindow from "../Drag";

const Notes = () => {
  const { currentUser, notes, toggleInventory } = useContext(UserContext);
  const [userNotes, setUserNotes] = useState("");
  const [money, setMoney] = useState(0);
  const [inventory, setInventory] = useState("");

  useEffect(() => {
    if (currentUser) {
      getNotes(currentUser.uid);
    }
  }, [currentUser]);

  useEffect(() => {
    if (localStorage.getItem("userNotes")) {
      setUserNotes(localStorage.getItem("userNotes"));
    }
    if (localStorage.getItem("money")) {
      setMoney(localStorage.getItem("money"));
    }
    if (localStorage.getItem("inventory")) {
      setInventory(localStorage.getItem("inventory"));
    }
  }, []);

  const getNotes = async (userId) => {
    const notesRef = doc(notes, userId);
    const notesDoc = await getDoc(notesRef);

    if (notesDoc.exists()) {
      setUserNotes(notesDoc.data().content);
      setMoney(notesDoc.data().money);
      setInventory(notesDoc.data().inventory);

      // Stocker les données dans le localStorage s'ils n'y sont pas déjà
      if (!localStorage.getItem("userNotes")) {
        localStorage.setItem("userNotes", notesDoc.data().content);
      }
      if (!localStorage.getItem("money")) {
        localStorage.setItem("money", notesDoc.data().money);
      }
      if (!localStorage.getItem("inventory")) {
        localStorage.setItem("inventory", notesDoc.data().inventory);
      }
    } else {
      setUserNotes("");
      setMoney(0);
      setInventory("");
    }
  };

  const saveNotes = async () => {
    if (currentUser) {
      await setDoc(doc(notes, currentUser.uid), {
        userId: currentUser.uid,
        content: userNotes,
        money: money,
        inventory: inventory,
      });
    }
    updateLocalStorage();
  };

  const updateLocalStorage = () => {
    localStorage.setItem("userNotes", userNotes);
    localStorage.setItem("money", money);
    localStorage.setItem("inventory", inventory);
  };
  const windowX = window.innerWidth * 0.3; 
  const windowY = window.innerHeight * 0.3; 

  return (
    <DraggableWindow windowId="window1" initialX={windowX} initialY={windowY}>
      
    <div className="notes-container">
      <div className="notes-icon"> 
      <div></div>
      <h2 className="notes-title">Inventaire</h2>
        <IoIosCloseCircle onClick={toggleInventory}  className="croix" size="2em" /> 
 </div>
      <div className="notes-col">
        <label className="notes-label">
          Credit :
          <input
            type="number"
            className="notes-input"
            value={money}
            onChange={(e) => setMoney(e.target.value)}
          />
        </label>
      </div>

      <div className="notes-row">
        <div className="notes-col">
          <label className="notes-label">
            Inventaire:
            <textarea
              className="notes-textarea first"
              value={inventory}
              onChange={(e) => setInventory(e.target.value)}
            />
          </label>
        </div>

        <div className="notes-row">
          <div className="notes-col">
            <label className="notes-label">
              Notes:
              <textarea
                className="notes-textarea"
                value={userNotes}
                onChange={(e) => setUserNotes(e.target.value)}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="notes-button-container">
        <button className="notes-button" onClick={saveNotes}>
          Enregistrer
        </button>
        <p style={{ color: "red", fontSize: "10px" }}>
          N'oublie pas d'enregistrer
        </p>
      </div>
    </div>
    </DraggableWindow>
  );
};

export default Notes;
