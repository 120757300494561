import React, {useContext} from 'react'
import "./Footer.css"
import {AiTwotoneHome} from "react-icons/ai"
import { GiTombstone } from "react-icons/gi";
import { UserContext } from '../../context/userContext'
import { Link } from 'react-router-dom';

const Footer = () => {

  const {currentUser} = useContext(UserContext)
    const year = new Date().getFullYear();
  return (
    <>
    
    <footer className="dn-footer shapedividers_com-7007">
    
    <a href="https://www.holysheet.fr" className="navbar-item">
          <span className="icon">
            <AiTwotoneHome />
          </span>
          <span>HolySheet</span>
        </a>
        <div>


      <p className="dn-copyright">&copy; {year} Dieu Nathan. </p>
     </div>

    <Link to="/RIP" className="navbar-item">
          <span className="icon">
            <GiTombstone />
          </span>
          <span>RIP</span>
        </Link> 

   
      </footer>
      </>
  )
}

export default Footer