import React from 'react'
import AvailabilityPoll from '../Sidebar/Calendrier'

const Laboratoire = () => {
  return (
    <div>
        <AvailabilityPoll />
    </div>
  )
}

export default Laboratoire