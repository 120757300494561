import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CoffreFort from "./Composent/Coffre";
import Connexion from "./Composent/Connexion";
import Footer from "./Composent/Footer";
import Inscription from "./Composent/Inscription";
import Maps from "./Composent/Maps";
import CyberpunkNavbar from "./Composent/NavBar";
import Personnages from "./Composent/Personnages";
import Resume from "./Composent/Resume";
import MusicPage from "./Composent/RIP";
import Usine from "./Composent/Usine";
import React from "react";
import Error404 from "./Composent/Error404";
import Sidebar from "./Composent/Sidebar";
import Laboratoire from "./Composent/Laboratoire";





function App() {



  return (
    <div className="app">
 

      <Sidebar />
      <Inscription />
      <Connexion />
      <CyberpunkNavbar
      />
      <Routes>
        <Route path="/" element={<Resume />} />
        <Route path="/personnages" element={<Personnages />} />
        <Route path="/maps" element={<Maps />} />
        <Route path="/jdr" element={<Usine />} />
        <Route path="/enigme" element={<CoffreFort />} />
        <Route path="/RIP" element={<MusicPage />} />
        <Route path="/laboratoire" element={<Laboratoire />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        limit={1}
      />
    </div>
  );
}

export default App;
