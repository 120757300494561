import React from 'react'
import { Navigate } from "react-router-dom";

const Error404 = () => {




  return (
    <div><Navigate to="/" /></div>
  )
}

export default Error404