
import "./CoffreFort.css";
import Ouvert from "../../images/ouvert.jpg";
import Fermé from "../../images/ferme.jpg";
import React, { useState, useContext } from "react";
import { UserContext } from '../../context/userContext'
import { Navigate } from "react-router-dom";

const CoffreFort = () => {
  const [selectedWords, setSelectedWords] = useState(["","","","","","",""]);
  const [isOpen, setIsOpen] = useState(false);

  const allPossibleCodes = [
    ["ADOPTION", "UN BARBU", "BAGUETTE", "ECOLE","AMIS","UNE PIERRE","UNE MORT"],
    ["MALEDICTION","PARTENARIAT","VOL","UNE PERLE","TRESOR","PISTOLET","UNE MORT"],
    ["VAISSEAU","AVERTISSEMENT","DES PIERRES","PLAN","FILICIDE","BATAILLE","DEFAITE"],
    ["ANNEAU","FUITE","REUNION","MINE","FORET","CHUTES","UNE MORT"],
    ["LIVRE","MORT","UN PSEUDO","DIEU","POLICE","DUEL","VICTOIRE"],
  ];
  const handleSelectChange = (e, index) => {
    const newSelectedWords = [...selectedWords];
    newSelectedWords[index] = e.target.value;
    setSelectedWords(newSelectedWords);
  };
    const handleSubmit = () => {
      const isOpen = allPossibleCodes.some(possibleCode => {
        return possibleCode.every((word, i) => word === selectedWords[i]);
      });
      setIsOpen(isOpen);
  };

  const words = [
    ["LIVRE", "VAISSEAU", "ANNEAU","MALEDICTION","ADOPTION"],
    ["FUITE", "PARTENARIAT", "AVERTISSEMENT","UN BARBU","MORT"],
    ["VOL", "BAGUETTE", "DES PIERRES","REUNION","UN PSEUDO"],
    ["UNE PERLE", "PLAN", "ECOLE","DIEU","MINE"],
    ["TRESOR","FORET","POLICE","AMIS","FILICIDE"],
    ["DUEL","CHUTES","PISTOLET","BATAILLE","UNE PIERRE"],
    ["UNE MORT","VICTOIRE","MORT","DEFAITE","UNE MORT"],
  ];
  const {currentUser} = useContext(UserContext)

  if(!currentUser) {
      return <Navigate to="/" />
  }
 return (
    <div className="coffre-fort">
      <img src={isOpen ? Ouvert : Fermé} alt="Coffre-fort" />
      <div className="selecteurs">
        {words.map((wordOptions, index) => (
          <select
            key={index}
            value={selectedWords[index]}
            onChange={(e) => handleSelectChange(e, index)}
          >
            <option value="" disabled>
              Sélectionnez un mot
            </option>
            {wordOptions.map((word, i) => {
              return (
                <option key={i} value={word}>
                  {word}
                </option>
              );
            })}
          </select>
        ))}
      </div>
      <button onClick={handleSubmit}>Valider</button>
    </div>
  );
};

export default CoffreFort;
