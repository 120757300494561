import React, { useContext } from "react";
import "./CyberpunkNavbar.css";
import { Link } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { FaListAlt } from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";
import { FaMapSigns } from "react-icons/fa";
import { TfiPencilAlt } from "react-icons/tfi";
import { FiLogIn } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { IoGameController } from "react-icons/io5";
import { UserContext } from "../../context/userContext";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";
import { GiPotionBall } from "react-icons/gi";

const CyberpunkNavbar = () => {
  const {
    toggleModals,
    currentUser,
    toggleDecoDice,
    toggleDecoInventory,
    userData,
    toggleDecoShow,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const specificUserName = "Nathan";

  const logOut = async () => {
    try {
      await signOut(auth);

      navigate("/");
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
      });

      window.localStorage.clear();
      window.sessionStorage.clear();

      // Recharger la page pour s'assurer que les modifications ont bien été effectuées
      window.location.reload();
    } catch {
      alert("Tu as surement pas de connexion internet !");
    }
  };

  return (
    <>
      <div className="navbar-wrapper">
        <Link to="/" className="navbar-item">
          <span className="icon">
            <FaListAlt />
          </span>
          <span>Résumé</span>
        </Link>
        <Link to="/personnages" className="navbar-item">
          <span className="icon">
            <FaUserAlt />
          </span>
          <span>Persos</span>
        </Link>
        <Link to="/maps" className="navbar-item">
          <span className="icon">
            <FaMapSigns />
          </span>
          <span>Maps</span>
        </Link>

        {currentUser ? (
          <Link to="/enigme" className="navbar-item">
            <span className="icon">
              <BsFillGearFill />
            </span>
            <span>Enigme</span>
          </Link>
        ) : (
          ""
        )}

        {currentUser && (
          <Link to="/jdr" className="navbar-item jdr">
            <span className="icon">
              <IoGameController />
            </span>
            <span>Jeu</span>
          </Link>
        )}

        {/* {userData && userData.username === specificUserName &&
        <Link to="/laboratoire" className="navbar-item">
          <span className="icon">
            <GiPotionBall />
          </span>
          <span>Labo</span>
        </Link>  } */}

        {currentUser ? (
          ""
        ) : (
          <a onClick={() => toggleModals("signUp")} className="navbar-item">
            <span className="icon">
              <TfiPencilAlt />
            </span>
            <span>SingUp</span>
          </a>
        )}
        {currentUser ? (
          ""
        ) : (
          <a onClick={() => toggleModals("signIn")} className="navbar-item">
            <span className="icon">
              <FiLogIn />
            </span>
            <span>Login</span>
          </a>
        )}
        {currentUser ? (
          <a
            onClick={() => {
              logOut();
              toggleDecoDice();
              toggleDecoInventory();
              toggleDecoShow();
            }}
            className="navbar-item"
          >
            <span className="icon">
              <FiLogOut />
            </span>
            <span>Deco</span>
          </a>
        ) : (
          ""
        )}
      </div>

      <div className="shapedividers_com-7008"></div>
    </>
  );
};

export default CyberpunkNavbar;
