import { createContext, useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getDoc,
  collection,
  query,
  addDoc,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { users, db, auth, storage } from "../firebase-config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const UserContext = createContext();

export function UserContextProvider(props) {
  const [currentUser, setCurrentUser] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [userData, setUserData] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setLoadingData(false);

      if (user) {
        const unsubscribeUserData = onSnapshot(users(user.uid), (doc) => {
          setUserData(doc.data());
        });

        const unsubscribeImages = onSnapshot(
          query(collection(db, "images"), orderBy("createdAt", "desc"), limit(1)),
          (querySnapshot) => {
            const newImages = [];
            querySnapshot.forEach((doc) => {
              newImages.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            setImages(newImages);
          }
        );

        return () => {
          unsubscribeUserData();
          unsubscribeImages();
        };
      } else {
        setUserData(null);
      }
    });

    return unsubscribe;
  }, []);

  const signUp = (email, pwd) =>
    createUserWithEmailAndPassword(auth, email, pwd);
  const signIn = (email, pwd) => signInWithEmailAndPassword(auth, email, pwd);

  const notes = collection(db, "notes");
  const diceRolls = collection(db, "diceRolls");



  const [modalState, setModalState] = useState({
    signUpModal: false,
    signInModal: false,
  });

  const toggleModals = (modal) => {
    if (modal === "signIn") {
      setModalState({
        signUpModal: false,
        signInModal: true,
      });
    } else if (modal === "signUp") {
      setModalState({
        signUpModal: true,
        signInModal: false,
      });
    } else if (modal === "close") {
      setModalState({
        signUpModal: false,
        signInModal: false,
      });
    }
  };

  // Sidebar

  const [inventory, setInventory] = useState(false);
  const [dice, setDice] = useState(false);
  const [show, setShow] = useState(false);
  const [poll, setPoll] = useState(false);

  const toggleInventory = () => {
    setInventory(!inventory);
  };
  const toggleDice = () => {
    setDice(!dice);
  };

  const toggleShow = () => {
    setShow(!show)
  }
  const togglePoll = () => {
    setPoll(!poll)
  }

  const toggleDecoPoll = () => {
    setPoll(false);
  };

  const toggleDecoInventory = () => {
    setInventory(false);
  };

  const toggleDecoDice = () => {
    setDice(false);
  };

  const toggleDecoShow = () => {
    setShow(false);
  };


  const uploadImage = async (file, imageName) => {
    const imageRef = ref(storage, `images/${imageName}`);
    await uploadBytes(imageRef, file);
  
    const imageURL = await getDownloadURL(imageRef);
  
    await addDoc(collection(db, "images"), {
      name: imageName,
      url: imageURL,
      uploader: userData.username,
      createdAt: new Date(),
    });
  };


  return (
    <UserContext.Provider
      value={{
        modalState,
        toggleModals,
        signUp,
        currentUser,
        signIn,
        notes,
        diceRolls,
        inventory,
        dice,
        toggleDice,
        toggleInventory,
        toggleDecoInventory,
        toggleDecoDice,
        userData,
        setUserData,
        uploadImage,
        images,
        show, 
        toggleShow,
        toggleDecoShow,
        poll,
        togglePoll,
        toggleDecoPoll
      }}
    >
      {!loadingData && props.children}
    </UserContext.Provider>
  );
}
