import React, { useState, useEffect, useContext } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { UserContext } from "../../context/userContext";
import { RotatingSquare } from "react-loader-spinner";
import "./DiceMobile.css";
import { IoIosCloseCircle } from "react-icons/io";

const DiceRoller = () => {
  const [numFaces, setNumFaces] = useState(100);
  const [result, setResult] = useState(null);
  const [history, setHistory] = useState([]);
  const [userPseudo, setUserPseudo] = useState("");
  const [loading, setLoading] = useState(false);

  const { currentUser, toggleDice, userData } = useContext(UserContext);

  useEffect(() => {
    const localHistory = localStorage.getItem("diceHistory");
    if (localHistory) {
      setHistory(JSON.parse(localHistory));
    }
  }, []);

  useEffect(() => {
    const diceRollsRef = collection(db, "diceRolls");
    const q = query(diceRollsRef, orderBy("createdAt", "desc"), limit(10));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const rolls = [];
      querySnapshot.forEach((doc) => {
        rolls.push(doc.data());
      });
      setHistory(rolls);
      localStorage.setItem("diceHistory", JSON.stringify(rolls));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const rollDice = async () => {
    setResult(null);
    setLoading(true);
    const rollResult = Math.floor(Math.random() * numFaces) + 1;
    setResult(rollResult);

    setTimeout(async () => {
      await addDoc(collection(db, "diceRolls"), {
        numFaces,
        result: rollResult,
        username: userData.username,
        createdAt: new Date(),
      });
      setLoading(false);
    }, 2000);
  };

  return (
    <div className="dice-roller-container-mobile">
      <div className="dice-roller-icon">
        <div></div>
        <h2 className="dice-roller-title">Historique</h2>
        <IoIosCloseCircle size="2em" onClick={toggleDice} className="croix" />
      </div>
      <ul className="dice-roller-history">
        {history.length > 0 && (
          <li className="dice-roller-history-item">
            <div className="dice-roller-dernier">
              <p>Last Tirage →</p>{" "}
              <p>
                {" "}
                {history[0].username} a fait : {history[0].result}
              </p>
              <p>d{history[0].numFaces}</p>
            </div>
          </li>
        )}
        {history.slice(1).map((roll, index) => (
          <li key={index} className="dice-roller-history-item">
            {roll.username} a fait : {roll.result}
          </li>
        ))}
      </ul>

      <h2 className="dice-roller-title">Dice Roller</h2>
      <div className="dice-roller-input-container">
        <label className="dice-roller-label" htmlFor="numFaces-input">
          Nombre de faces:
        </label>
        <input
          type="number"
          min="2"
          value={numFaces}
          onChange={(e) => setNumFaces(parseInt(e.target.value))}
          className="dice-roller-input"
          id="numFaces-input"
        />
      </div>
      <button
        onClick={rollDice}
        disabled={loading}
        className="dice-roller-button"
      >
        Roll
      </button>
      {loading ? (
        <div className="dice-roller-loader">
          <RotatingSquare
            height="50"
            width="50"
            color="#35C6CB"
            ariaLabel="rotating-square-loading"
            strokeWidth="4"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        result !== null && (
          <p className="dice-roller-result">Resultat: {result}</p>
        )
      )}
    </div>
  );
};
export default React.memo(DiceRoller);
