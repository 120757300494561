import React, { useContext, useRef } from "react";
import { UserContext } from "../../../context/userContext";
import DraggableWindow from "../Drag";
import "./ShowImage.css";
import { IoIosCloseCircle } from "react-icons/io";

const ShowImage = () => {
const { uploadImage, currentUser, images, toggleShow } = useContext(UserContext);
const fileInput = useRef();

const handleUpload = async (e) => {
  e.preventDefault();

  const file = fileInput.current.files[0];
  if (!file) {
    alert("Veuillez choisir un fichier à uploader.");
    return;
  }

  if (file.size > 5 * 1024 * 1024) {
    alert("Le fichier est trop volumineux. Veuillez sélectionner un fichier de moins de 5Mo.");
    return;
  }


  const fileType = file.type.split("/")[0];
  if (fileType !== "image") {
    alert("Le fichier n'est pas une image. Veuillez sélectionner un fichier image.");
    return;
  }

  const imageName = `${currentUser.uid}-${Date.now()}-${file.name}`;
  await uploadImage(file, imageName);
};



const windowX = window.innerWidth * 0.2;
const windowY = window.innerHeight * 0.4;

return (
<DraggableWindow windowId="window3" initialX={windowX} initialY={windowY}>
<div className="image-uploader-container">
<div className="image-uploader-title">
<div></div>
<h2>Uploader une image</h2>
<IoIosCloseCircle onClick={toggleShow}  className="croix" size="2em" />
</div>
<form onSubmit={handleUpload} className="image-uploader-form">
<input type="file" ref={fileInput} className="image-uploader-input" accept="image/*" />
<button type="submit" className="image-uploader-button">
Uploader
</button>
</form>
<div className="image-gallery">
{images.map((image) => (
<div key={image.id} className="image-gallery-item">
<img
             src={image.url}
             alt={image.name}
             className="image-gallery-img"
           />
<p className="image-gallery-uploader">
Image postée par {image.uploader}
</p>
</div>
))}
</div>
</div>
</DraggableWindow>
);
};

export default ShowImage;