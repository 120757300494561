import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/userContext";
import { getDoc, setDoc, doc } from "firebase/firestore";
import "./NotesMobile.css";


const NotesMobile = () => {
  const { currentUser, notes } = useContext(UserContext);
  const [userNotes, setUserNotes] = useState("");
  const [money, setMoney] = useState(0);
  const [inventory, setInventory] = useState("");

  useEffect(() => {
    if (currentUser) {
      getNotes(currentUser.uid);
    }
  }, [currentUser]);

  const getNotes = async (userId) => {
    const notesRef = doc(notes, userId);
    const notesDoc = await getDoc(notesRef);
    if (notesDoc.exists()) {
      setUserNotes(notesDoc.data().content);
      setMoney(notesDoc.data().money);
      setInventory(notesDoc.data().inventory);
    } else {
      setUserNotes("");
      setMoney(0);
      setInventory("");
    }
  };

  const saveNotes = async () => {
    if (currentUser) {
      await setDoc(doc(notes, currentUser.uid), {
        userId: currentUser.uid,
        content: userNotes,
        money: money,
        inventory: inventory,
      });
    }
  };

  return (
    <div className="nm-container-mobile">
      <h2 className="nm-title">Inventaire</h2>
      <div className="nm-row">
        <div className="nm-col">
          <label className="nm-label">
            Credit :
            <input
              type="number"
              className="nm-input"
              value={money}
              onChange={(e) => setMoney(e.target.value)}
            />
          </label>
        </div>
        <div className="nm-col">
          <label className="nm-label">
            Inventaire:
            <textarea
              className="nm-textarea"
              value={inventory}
              onChange={(e) => setInventory(e.target.value)}
            />
          </label>
        </div>
      </div>
      <div className="nm-row">
        <div className="nm-col">
          <label className="nm-label">
            Notes:
            <textarea
              className="nm-textarea"
              value={userNotes}
              onChange={(e) => setUserNotes(e.target.value)}
            />
          </label>
        </div>
      </div>
      <div className="nm-row">
        <button className="nm-button" onClick={saveNotes}>
          Enregistrer
        </button>
        <p style={{ color: "red", fontSize: "10px" }}>
          N'oublie pas d'enregistrer
        </p>
      </div>
    </div>
  );
};

export default NotesMobile;
