import React, { useState, useEffect, useContext } from "react";
import "./Resume.css";
import HTMLFlipBook from "react-pageflip";
import Titan from "../../images/titan.jpg";
import Mine from "../../images/mine.jpg";
import Dome from "../../images/dome.jpg";
import Dome4 from "../../images/dome4.jpg";
import YouTube from "react-youtube";
import { UserContext } from "../../context/userContext";




const Resume = () => {
  const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
  const [showIframe, setShowIframe] = useState(null);
  const { currentUser,  } = useContext(UserContext);
  function handleClick() {
    setShowIframe(true);
  }

  function handleClose() {
    setShowIframe(false);
  }

  useEffect(() => {
    function handleResize() {
      setLargeurEcran(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

 

  return (
    <>
      <div className="resume">
        <div className="container-neon">
          <h1 className="neon-text">Cyber-Space Titan Quest</h1>
          <p className="p-text">
            Dans un contexte de guerre commerciale entre APS et Windows Explorer
            pour l'exploitation de la ceinture d'astéroïdes, le ministre du
            développement commercial d'APS demande l'aide de nos trois héros
            pour réactiver les mines de Titan. L'équipe est larguée sur Titan et
            découvre des étendues désertiques et des ruines.
          </p>
          

        </div>
        {largeurEcran > 667 && (
          <a onClick={handleClick}>
            <p className="bestoff">Best off</p>
          </a>
        )}
        {showIframe && largeurEcran > 667 && (
          <div className="overlay" onClick={handleClose}>
            <YouTube videoId="1d-1uDYtrBk" className="cyberpunk-style" />
          </div>
        )}

        {largeurEcran > 1016 ? (
          <HTMLFlipBook
            width={500}
            height={700}
            renderOnlyPageLengthChange={true}
          >
            <div className="livre-large">
              <h3>Épisode 1</h3>
              <h1 className="livre-titre-large">Titan : une mission risquée</h1>
              <img
                className="livre-img-large"
                src={Titan}
                alt="image de Titan"
              />
              <div className="livre-hr-large" />
              <p className="livre-p-large">
                Une guerre commerciale a éclaté entre APS et Windows Explorer
                pour l'exploitation de la ceinture d'astéroïdes, où le titane
                est de plus en plus rare. Le ministre du développement
                commercial d'APS souhaite remettre en service les mines de Titan
                et demande l'aide de Mylène et des trois compagnons pour
                réactiver les défenses militaires des dômes, convaincre les
                habitants de reprendre l'exploitation des mines et améliorer
                l'image d'APS. Sur Titan, l'équipe découvre des conditions de
                vie archaïques, des ruines, un ascenseur spatial, des hommes des
                sables et deux factions rivales dirigées par les frères Kostas
                et Ricardo. Après avoir été capturés par Ricardo, Deba accepte
                de l'aider à démasquer un traître parmi ses hommes et à tuer son
                frère Kostas pour prendre la tête des HDS.
              </p>
            </div>
            <div className="livre-large">
              <h3>Épisode 2</h3>
              <h1 className="livre-titre-large">
                Contrôle des HDS : Trois Missions
              </h1>
              <img className="livre-img-large" src={Mine} alt="image de Mine" />
              <div className="livre-hr-large" />
              <p className="livre-p-large">
                Après avoir aidé Ricardo à prendre le contrôle des HDS en tuant
                Kostas, l'équipe est chargée de trois missions : arrêter les
                raids sur le dôme 3, négocier des contrats commerciaux avec les
                autres dômes et retrouver le fils de Ricardo. En cherchant
                l'enfant, l'équipe est victime d'hallucinations causées par du
                Titanium explosif et perd Mylène. Ils se rendent ensuite au dôme
                2 et se retrouvent pris au piège dans un bus, mais parviennent à
                s'échapper. Ils défendent une femme HDS accusée de vol et font
                exploser la maison du prédicateur responsable de son lynchage
                avant de s'enfuir par les égouts. Le lendemain, la femme est
                innocentée et libérée.
              </p>
            </div>
            <div className="livre-large">
              {" "}
              <h3>Épisode 3</h3>
              <h1 className="livre-titre-large">
                La promesse de devenir diplomate intergalactique
              </h1>
              <img
                className="livre-img-large"
                src={Dome}
                alt="image de Dome 2"
              />
              <div className="livre-hr-large" />
              <p className="livre-p-large">
                L'équipe se rend au dôme 2 pour trouver Saroyan, qui pourrait
                les aider à retrouver le fils de Ricardo. Ils négocient avec
                Vector pour qu'il les emmène chez Totu, qui connaît
                l'emplacement de Saroyan. Ils font également affaire avec
                Andreas, le seul fournisseur de la drogue "la poudre rouge" dans
                le dôme 2, en lui promettant de le faire devenir le fournisseur
                officiel du dôme 1 et 2 en échange d'un échantillon. Ils se
                rendent chez Izir, un booker du laborieux où Saroyan pourrait se
                trouver. Ils participent à un combat en arène pour pouvoir
                discuter avec Izir en privé et rencontrent Raphael, le fils de
                Ricardo, ainsi qu'une petite fille qu'ils sauvent. Ils
                rencontrent Saroyan et lui promettent de devenir le diplomate
                intergalactique s'il réunit toutes les grandes pontes pour une
                réunion dans plusieurs semaines. Ils remercient Ricardo en lui
                rendant son fils et en stockant un cube de nanotech dans leur
                vaisseau.
              </p>
            </div>
            <div className="livre-large">
              {" "}
              <h3>Épisode 4</h3>
              <h1 className="livre-titre-large">
                La découverte de la porte du dôme 4
              </h1>
              <img
                className="livre-img-large"
                src={Dome4}
                alt="image de Dome 4"
              />
              <div className="livre-hr-large" />
              <p className="livre-p-large">
                L'équipe change de plan et retourne chercher le cube de nanotech
                pour créer un Hummer blindé équipé d'une tourelle Gauss et
                submersible <span className="tank">(Le TechnoTank)</span> . Ils
                se dirigent vers la porte du dôme 4, que Jolyne trouve enfouie
                sous le sable. En ouvrant la porte, une dépressurisation
                commence à aspirer le sable et ensevelit Warrak et le véhicule,
                révélant l'immensité du Krieger caché sous le sable. Ils
                continuent vers le dôme 4, où Deba heurte et décapite un enfant,
                provoquant la colère des habitants. Un plan est mis en place
                pour faire passer Jolyne et Warrak pour des héros en aidant le
                village à éteindre les incendies causés par Deba. Cependant,
                Deba est gravement blessé et le véhicule n'a plus d'énergie.
              </p>
            </div>
          </HTMLFlipBook>
        ) : (
          <div className="block">
            {" "}
            <HTMLFlipBook width={300} height={500}>
              <div className="livre">
                <h3>Épisode 1</h3>
                <h1 className="livre-titre">Titan : une mission risquée</h1>
                <img className="livre-img" src={Titan} alt="image de Titan" />
                <div className="livre-hr" />
                <p className="livre-p">
                  Une guerre commerciale a éclaté entre APS et Windows Explorer
                  pour l'exploitation de la ceinture d'astéroïdes, où le titane
                  est de plus en plus rare. Le ministre du développement
                  commercial d'APS souhaite remettre en service les mines de
                  Titan et demande l'aide de Mylène et des trois compagnons pour
                  réactiver les défenses militaires des dômes, convaincre les
                  habitants de reprendre l'exploitation des mines et améliorer
                  l'image d'APS. Sur Titan, l'équipe découvre des conditions de
                  vie archaïques, des ruines, un ascenseur spatial, des hommes
                  des sables et deux factions rivales dirigées par les frères
                  Kostas et Ricardo. Après avoir été capturés par Ricardo, Deba
                  accepte de l'aider à démasquer un traître parmi ses hommes et
                  à tuer son frère Kostas pour prendre la tête des HDS.
                </p>
              </div>
              <div className="livre">
                <h3>Épisode 2</h3>
                <h1 className="livre-titre">
                  Contrôle des HDS : Trois Missions
                </h1>
                <img className="livre-img" src={Mine} alt="image de Mine" />
                <div className="livre-hr" />
                <p className="livre-p">
                  Après avoir aidé Ricardo à prendre le contrôle des HDS en
                  tuant Kostas, l'équipe est chargée de trois missions : arrêter
                  les raids sur le dôme 3, négocier des contrats commerciaux
                  avec les autres dômes et retrouver le fils de Ricardo. En
                  cherchant l'enfant, l'équipe est victime d'hallucinations
                  causées par du Titanium explosif et perd Mylène. Ils se
                  rendent ensuite au dôme 2 et se retrouvent pris au piège dans
                  un bus, mais parviennent à s'échapper. Ils défendent une femme
                  HDS accusée de vol et font exploser la maison du prédicateur
                  responsable de son lynchage avant de s'enfuir par les égouts.
                  Le lendemain, la femme est innocentée et libérée.
                </p>
              </div>
              <div className="livre">
                {" "}
                <h3>Épisode 3</h3>
                <h1 className="livre-titre">
                  La promesse de devenir diplomate intergalactique
                </h1>
                <img className="livre-img" src={Dome} alt="image de Dome 2" />
                <div className="livre-hr" />
                <p className="livre-p">
                  L'équipe se rend au dôme 2 pour trouver Saroyan, qui pourrait
                  les aider à retrouver le fils de Ricardo. Ils négocient avec
                  Vector pour qu'il les emmène chez Totu, qui connaît
                  l'emplacement de Saroyan. Ils font également affaire avec
                  Andreas, le seul fournisseur de la drogue "la poudre rouge"
                  dans le dôme 2, en lui promettant de le faire devenir le
                  fournisseur officiel du dôme 1 et 2 en échange d'un
                  échantillon. Ils se rendent chez Izir, un booker du laborieux
                  où Saroyan pourrait se trouver. Ils participent à un combat en
                  arène pour pouvoir discuter avec Izir en privé et rencontrent
                  Raphael, le fils de Ricardo, ainsi qu'une petite fille qu'ils
                  sauvent. Ils rencontrent Saroyan et lui promettent de devenir
                  le diplomate intergalactique s'il réunit toutes les grandes
                  pontes pour une réunion dans plusieurs semaines. Ils
                  remercient Ricardo en lui rendant son fils et en stockant un
                  cube de nanotech dans leur vaisseau.
                </p>
              </div>
              <div className="livre">
                {" "}
                <h3>Épisode 4</h3>
                <h1 className="livre-titre">
                  La découverte de la porte du dôme 4
                </h1>
                <img className="livre-img" src={Dome4} alt="image de Dome 4" />
                <div className="livre-hr" />
                <p className="livre-p">
                  L'équipe change de plan et retourne chercher le cube de
                  nanotech pour créer un Hummer blindé équipé d'une tourelle
                  Gauss et submersible{" "}
                  <span className="tank">(Le TechnoTank)</span> . Ils se
                  dirigent vers la porte du dôme 4, que Jolyne trouve enfouie
                  sous le sable. En ouvrant la porte, une dépressurisation
                  commence à aspirer le sable et ensevelit Warrak et le
                  véhicule, révélant l'immensité du Krieger caché sous le sable.
                  Ils continuent vers le dôme 4, où Deba heurte et décapite un
                  enfant, provoquant la colère des habitants. Un plan est mis en
                  place pour faire passer Jolyne et Warrak pour des héros en
                  aidant le village à éteindre les incendies causés par Deba.
                  Cependant, Deba est gravement blessé et le véhicule n'a plus
                  d'énergie.
                </p>
              </div>
            </HTMLFlipBook>{" "}
          </div>
        )}
        
      </div>
    </>
  );
};

export default React.memo(Resume);
