import React from "react";
import "./Calendrier.css"
import DraggableWindow from "../Drag";


const Disponibilites = () => {



  const windowX = window.innerWidth * 0.3; 
  const windowY = window.innerHeight * 0.3; 


  return (
    <DraggableWindow windowId="window4" initialX={windowX} initialY={windowY}>
    <div className="strawpoll-embed-container">
    <div className="strawpoll-embed" id="strawpoll_eNg6945NAnA">
      <iframe
        className="strawpoll-embed-iframe"
        title="StrawPoll Embed"
        id="strawpoll_iframe_eNg6945NAnA"
        src="https://strawpoll.com/embed/polls/eNg6945NAnA"
        allowFullScreen
        allowtransparency
      >
        Loading...
      </iframe>
      <script async src="https://cdn.strawpoll.com/dist/widgets.js" charset="utf-8"></script>
    </div>
  </div>
  </DraggableWindow>
  );
};

export default Disponibilites;
