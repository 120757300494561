import React, { useContext, useRef, useState, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { users } from "../../firebase-config";
import { setDoc } from "firebase/firestore";
import { toast } from "react-toastify";

import "./Inscription.css";

const Inscription = () => {
  const { toggleModals, modalState, signUp, userData } = useContext(UserContext);

  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [validation, setValidation] = useState("");
  const emailInput = useRef();
  const usernameInput = useRef();
  const passwordInput = useRef();
  const confirmPasswordInput = useRef();
  

  
  const handleForm = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    await createUser();
  };

  const validateForm = () => {
    if (passwordInput.current.value.length < 6) {
      setValidation("Le mot de passe doit comporter au moins 6 caractères");
      return false;
    } else if (passwordInput.current.value !== confirmPasswordInput.current.value) {
      setValidation("Les mots de passe ne correspondent pas");
      return false;
    } else {
      setValidation("");
      return true;
    }
  };

  


  const createUser = async () => {
    try {
      const userCredential = await signUp(emailInput.current.value, passwordInput.current.value);
      const { user } = userCredential;

      const username = usernameInput.current.value;
      const email = emailInput.current.value;

      await setDoc(users(user.uid), {
        username: username,
        email: email
      });

      setLoggedIn(true);

      toggleModals("close");
      navigate("/");
     
    } catch (err) {
      if (err.code === "auth/invalid-email") {
        setValidation("L'adresse email n'est pas valide");
      } else if (err.code === "auth/email-already-in-use") {
        setValidation("Cette adresse email est déjà utilisée");
      }
    }
  };
  useEffect(() => {
    if (loggedIn && userData) {
      const messages = [
        `Bienvenue ${userData.username} !`,
        `Salut ${userData.username}, content de te voir !`,
        `Félicitations ${userData.username}, tu es maintenant inscrit !`,
        `Heureux de te compter parmi nous, ${userData.username}`,
      ];

      const randomMessage = messages[Math.floor(Math.random() * messages.length)];
      toast.success(randomMessage);
    }
  }, [loggedIn, userData]);

  const closeModal = () => {
    setValidation("");
    toggleModals("close");
  };

  return (
    <>
  
      {modalState.signUpModal && (
        <div className="position">
          <div className="overlay-inscription" onClick={closeModal}></div>
          <div className="container-position">
            <div className="container-inscription">
              <h2>Inscription</h2>
              <form onSubmit={handleForm} className="form">
                <div className="label">
                  <label htmlFor="signUpEmail" >Email</label>
                  <input ref={emailInput} type="email" name="email" required id="signUpEmail" />
                </div>

                <div className="label">
                  <label htmlFor="signUpPseudo">Pseudo</label>
                  <input ref={usernameInput} type="text" name="pseudo" required id="signUpPseudo" />
                </div>

                <div className="label">
                  <label htmlFor="signUpPwd">Mot de passe</label>
                  <input ref={passwordInput} type="password" name="pwd" required id="signUpPwd" />
                </div>

                <div className="label">
                  <label htmlFor="repeatPwd">Confirmer le mot de passe</label>
                  <input ref={confirmPasswordInput} type="password" name="pwd" required id="repeatPwd" />
                </div>

                <p style={{ color: "red" }}>{validation}</p>

                <button className="cyberpunk-button" type="submit">
                  S'inscrire
                </button>
                </form>
      </div>
    </div>
    </div>
  )}
</>

);
};


export default Inscription;