import { useState, useContext } from "react";
import { UserContext } from "../../context/userContext";
import { GiSchoolBag } from "react-icons/gi";
import { BsDice6Fill } from "react-icons/bs";
import { BsArrowRightSquareFill } from "react-icons/bs";
import { AiFillPicture } from "react-icons/ai";
import { BsCalendarDateFill } from "react-icons/bs";
import Notes from "./Notes";
import DiceRoller from "./DiceRoller";
import "./Sidebar.css";
import ShowImage from "./ShowImage";
import Disponibilites from "./Calendrier";

function Sidebar() {
  const {
    currentUser,
    toggleDice,
    toggleInventory,
    dice,
    inventory,
    show,
    toggleShow,
    poll,
    togglePoll
  } = useContext(UserContext);
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible(!visible);
  };

  return (
    <>
      {currentUser && (
        <div className={`sidebar-wrapper ${visible ? "visible" : ""}`}>
         
          <div className="sidebar-icon" onClick={toggleVisible}>
            <BsArrowRightSquareFill size="2em" />
          </div>
          <div
            className={`${
              visible ? "sidebar-content-visible" : "sidebar-content"
            }`}
          >
            <div onClick={toggleInventory} className="sidebar-icon">
              <GiSchoolBag size="2em" />
            </div>
            <div onClick={toggleDice} className="sidebar-icon">
              <BsDice6Fill size="2em" />
            </div>
            <div onClick={toggleShow} className="sidebar-icon">
              <AiFillPicture size="2em" />
            </div>
            <div onClick={togglePoll} className="sidebar-icon">
              <BsCalendarDateFill size="2em" />
            </div>
          </div>
          
        </div>
      )}
      {poll && <Disponibilites />}
      {inventory && <Notes />}
      {dice && <DiceRoller />}
      {show && <ShowImage />}

    </>
  );
}

export default Sidebar;
