import React, { useState, useEffect } from 'react';

function DraggableWindow({ initialX, initialY, windowId, children }) {
  const [position, setPosition] = useState({
    x: initialX,
    y: initialY,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const storedPosition = localStorage.getItem(`${windowId}-position`);
    if (storedPosition) {
      setPosition(JSON.parse(storedPosition));
    }
  }, [windowId]);

  const handlePointerDown = (event) => {
    const tagName = event.target.tagName.toLowerCase();
    if (
      event.target === event.currentTarget ||
      (tagName !== 'input' && tagName !== 'textarea')
    ) {
      setIsDragging(true);
      setDragOffset({
        x: event.clientX - position.x,
        y: event.clientY - position.y,
      });
    }
  };

  const handlePointerMove = (event) => {
    if (isDragging) {
      setPosition({
        x: event.clientX - dragOffset.x,
        y: event.clientY - dragOffset.y,
      });
    }
  };

  const handlePointerUp = () => {
    localStorage.setItem(`${windowId}-position`, JSON.stringify(position));
    setIsDragging(false);
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
    >
      {children}
    </div>
  );
}

export default DraggableWindow;
