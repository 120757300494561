import React, { useContext, useState, useEffect } from "react";
import { UserContext } from '../../context/userContext'
import { Navigate } from "react-router-dom";

import "./Labo.css"

import NotesMobile from "../NotesMobile";
import DiceRollerMobile from "../DiceMobile";


const Usine = () => {

  const {currentUser} = useContext(UserContext)


  const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setLargeurEcran(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if(!currentUser || largeurEcran > 770) {
      return <Navigate to="/" />
  }

  return (
    <div className="labo">
      <h1 style={{textAlign: "center", fontFamily: "Neon, sans-serif"}}>
        Espace de jeu pour Smartphone
      </h1>
      <NotesMobile />
      <DiceRollerMobile />


    </div>
  );
};

export default Usine;
