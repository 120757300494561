import React, { useContext, useRef, useState, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Connexion = () => {
  const {
    toggleModals,
    modalState,
    signIn,
    currentUser,
    userData,
  } = useContext(UserContext);

  const [loggedIn, setLoggedIn] = useState(false);

  const navigate = useNavigate();

  const [validation, setValidation] = useState("");

  const inputs = useRef([]);
  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };

  const formRef = useRef();

  const handleForm = async (e) => {
    e.preventDefault();

    try {
      await signIn(inputs.current[0].value, inputs.current[1].value);

      setLoggedIn(true);
      setValidation("");
      toggleModals("close");
      navigate("/");
    } catch {
      setValidation("Pas le bon Email ou Mot de Passe");
    }
  };

  useEffect(() => {
    if (loggedIn && userData && userData.username) {
      const messages = [
        `Je suis content de te revoir ${userData.username}`,
        `Tu vas faire des echecs critiques ${userData.username}`,
        ` ${userData.username} est quelqu'un de génial`,
        `${userData.username} est pour la réforme des retraites`,
        `Hey ${userData.username} comment vas-tu ?`,
        `Bin alors ${userData.username} tu viens plus au soirée ?`,
        `Je te recommande le groupe de KPOP Twice ${userData.username}`,
        `^^ ${userData.username}`,
        `La chance va te sourire ${userData.username}`,
      ];

      const randomMessage = messages[Math.floor(Math.random() * messages.length)];
      toast.info(randomMessage);
    }
  }, [loggedIn, userData]);

  const closeModal = () => {
    setValidation("");
    toggleModals("close");
  };


  return (
    <>
    
      {modalState.signInModal && (
        <div className="position">
          <div className="overlay-inscription" onClick={closeModal}></div>
          <div className="container-position">
            <div className="container-inscription">
              <h2>Connexion</h2>
              <form ref={formRef} onSubmit={handleForm} className="form">
                <div className="label">
                  <label htmlFor="signInEmail">Email</label>
                  <input
                    ref={addInputs}
                    name="email"
                    required
                    type="email"
                    id="signInEmail"
                  />
                </div>

                <div className="label">
                  <label htmlFor="signInPwd">Mot de passe</label>
                  <input
                    ref={addInputs}
                    name="pwd"
                    required
                    type="password"
                    className="form-control"
                    id="signInPwd"
                  />
                </div>
                <p style={{ color: "red" }}>{validation}</p>
                <button className="cyberpunk-button" type="submit">
                  Connexion
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Connexion;
